import React, { ReactNode, useEffect, useState } from 'react';
import { useReduxDispatch } from '../../redux/create-store';
import { Button, Icon, ProLogo } from '@whoop/web-components';
import styles from './free-reward-toggle.module.scss';
import { claimReward } from '../../redux/reducers/cartReducer';
import {
  useCartItemCount,
  useFreeRewardStatus,
  useHasFreeEligibleItemsInCart,
  useHasProInCart,
  useIsCartLoaded,
  useIsCartMutating,
  useIsEligibleForProCheckout,
  useIsFreeRewardApplied,
  useIsSignedIn,
  useNextRewardDate,
  useProMonthlyPrice,
  useProNMonthsPrice,
  useProProratedMonths,
} from '../../redux/hooks';
import {
  useAddProToCart,
  useToggleCart,
  useToggleProModal,
} from '../../redux/action-hooks';
import { useSelector } from 'react-redux';
import { Store } from '../../types';
import { ShopifyStorefront } from '../../types/shopify-storefront';
import { trackToggleFreeItem } from '../../utils/analytics';
import { Link } from 'gatsby';
import { isFreeCheckoutItem } from '../../utils/products';
import { useTranslation } from 'gatsby-plugin-react-i18next';

interface SimpleCtaProps {
  withProLogo?: boolean;
  children?: ReactNode;
}
function SimpleCta({ withProLogo, children }: SimpleCtaProps): JSX.Element {
  const { t } = useTranslation('cart');
  return (
    <div aria-label={t('aria.freeRewardHint')} className={styles.proCta}>
      {withProLogo && <ProLogo className={styles.proLogo} />}
      <p className={styles.hintText}>{children}</p>
    </div>
  );
}

export default function FreeRewardToggle(): JSX.Element | null {
  const { t } = useTranslation('cart');
  const freeRewardStatus = useFreeRewardStatus();
  const hasFreeEligibleItems = useHasFreeEligibleItemsInCart();
  const proInCart = useHasProInCart();
  const nextRewardDate = useNextRewardDate();
  const isCartLoaded = useIsCartLoaded();
  const isMutating = useIsCartMutating();
  const isChecked = useIsFreeRewardApplied();
  const dispatch = useReduxDispatch();
  const hasItems = (useCartItemCount() || 0) > 0;
  const addProToCart = useAddProToCart();
  const isEligibleForProCheckout = useIsEligibleForProCheckout();
  const monthlyPrice = useProMonthlyPrice();
  const proMonthsRequired = useProProratedMonths();
  const creditCost = useProNMonthsPrice(proMonthsRequired || 0);
  const yearlyCost = useProNMonthsPrice(12);
  const isSignedIn = useIsSignedIn();

  const [isToggleClicked, setIsToggleClicked] = useState(false);
  const toggleChecked = () => {
    dispatch(claimReward(!isChecked));
    setIsToggleClicked(true);
  };
  const toggleCart = useToggleCart();
  const toggleProModal = useToggleProModal('reward-toggle', true);

  const checkout = useSelector<Store>(
    (state) => state.checkout,
  ) as ShopifyStorefront.Checkout;

  useEffect(() => {
    // Reset cart component state if/when user is changed
    setIsToggleClicked(false);
  }, [isSignedIn]);

  useEffect(() => {
    if (isToggleClicked) {
      if (isChecked) {
        const freeItem = checkout.lineItems.find((item) =>
          isFreeCheckoutItem(item),
        );
        trackToggleFreeItem(true, freeItem);
      } else {
        trackToggleFreeItem(false);
      }
    }
  }, [checkout]);

  if (!isCartLoaded) return null;

  switch (freeRewardStatus) {
    case 'eligible':
      if (hasFreeEligibleItems) {
        if (proInCart) {
          return (
            <SimpleCta>
              {t('wpCTA', {
                price: creditCost,
                months: proMonthsRequired,
                yearlyCost: yearlyCost,
              })}
              <br />
              <a className='pro-anchor no-color' onClick={toggleProModal}>
                {t('learnMore')}
              </a>
            </SimpleCta>
          );
        }
        return (
          <div>
            <label className={styles.rewardToggle}>
              <input
                type='checkbox'
                aria-label={t('aria.claimWPItem')}
                onChange={toggleChecked}
                checked={isChecked}
                disabled={isMutating || !isCartLoaded}
              />
              <span className={styles.checkBox}>
                <Icon name='check' />
              </span>
              {t('claimWPItem')}
            </label>
            <p>{t('highestPricedItemFree')}</p>
          </div>
        );
      }
      return (
        <div className={styles.goldCard} aria-label={t('aria.freeRewardHint')}>
          <p className={styles.hintText}>
            {proInCart ? t('freeItemWithNewWhoopPro') : t('freeItemAvailable')}
          </p>
          <span
            data-event='WHOOP Pro Free Item Eligible'
            data-event-source='cart'
          >
            <Icon name='gift' />
            <Link
              to='/collections/whoop-pro'
              onClick={toggleCart}
              className='no-color'
              data-event='Choose Now'
              data-event-source='cart'
            >
              <b>{t('chooseNow')}</b>
            </Link>
          </span>
        </div>
      );
    case 'will-be-eligible':
      return (
        <SimpleCta withProLogo={true}>
          {t('claimNextFreeItemOn', { date: nextRewardDate })}
        </SimpleCta>
      );
    case 'pending':
      return (
        <SimpleCta withProLogo={true}>{t('cannotClaimUntilPaid')}</SimpleCta>
      );
    default:
      if (hasItems && isEligibleForProCheckout) {
        return (
          <div aria-label={t('aria.freeRewardHint')} className={styles.proCta}>
            <p className={styles.hintText}>
              {t('freeItemThisOrder')}
              <br />
              <a className='pro-anchor no-color' onClick={toggleProModal}>
                {t('learnMore')}
              </a>
            </p>
            <Button
              label={t('addWPPerMonth', { price: monthlyPrice })}
              size='small'
              variant='secondary'
              data-event='Add Pro to Cart'
              data-event-source='cart'
              onClick={addProToCart}
            />
          </div>
        );
      }
      return null;
  }
}
