import { startExperiment } from '../utils/experiments';
import { useIsOnForAll } from '../utils/feature-flags';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Store } from '../types';

export enum CartRedesignVariant {
  'control' = 'control',
  'with_upsell' = 'with_upsell',
  'no_upsell' = 'no_upsell',
}

export function useCartRedesign(): CartRedesignVariant | undefined {
  const [variant, setVariant] = useState<CartRedesignVariant>();
  const featureIsOn = useIsOnForAll('storefront-cart-redesign');
  const showCart = useSelector((state: Store) => state.showCart);

  useEffect(() => {
    if (!variant && featureIsOn !== undefined && showCart) {
      if (featureIsOn) {
        setVariant(
          startExperiment('experiment_cart_redesign') as CartRedesignVariant,
        );
      } else {
        setVariant(CartRedesignVariant.control);
      }
    }
  }, [featureIsOn, showCart]);

  return variant;
}
