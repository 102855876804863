import React, { useEffect, useState } from 'react';
import styles from './full-cart.module.scss';
import { Button } from '@whoop/web-components';
import LineItem from '../LineItem';
import { useSelector } from 'react-redux';
import { classes } from '../../utils';
import { trackViewCart } from '../../utils/analytics';
import { goToCheckout } from '../../utils/shopify';
import { useReduxDispatch } from '../../redux/create-store';
import FreeRewardToggle from '../FreeRewardToggle';
import { Store } from 'src/types';
import CartSubtotal from '../CartSubtotal';
import { hideCart } from '../../redux/reducers/viewReducer';
import {
  removeLineItem,
  updateLineItem,
} from '../../redux/reducers/cartReducer';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { useIsCartMutating, useLineItems } from '../../redux/hooks';
import { ShopifyStorefront } from '../../types/shopify-storefront';
import useShopifyCheckoutItemMap from '../../hooks/useShopifyCheckoutItemMap';

const Header = () => {
  const { t } = useTranslation('cart');
  return (
    <div className={styles.header}>
      <h5 className={styles.headerItem}>{t('product')}</h5>
      <h5 className={styles.headerItem}></h5>
      <h5 className={classes(styles.headerItem, styles.quantity)}>
        {' '}
        {t('quantity')}{' '}
      </h5>
      <h5 className={classes(styles.headerItem, styles.total)}>{t('total')}</h5>
    </div>
  );
};

const FullCart = (): JSX.Element => {
  const { t } = useTranslation('cart');
  const dispatch = useReduxDispatch();
  const checkout = useSelector((state: Store) => state.checkout);
  const cartItemsBySku = useShopifyCheckoutItemMap(checkout);
  const isMutating = useIsCartMutating();

  useEffect(() => {
    dispatch(hideCart());
  }, []);

  // Track the cart view once after the checkout data becomes available. Note: when the page is initially loaded,
  // the checkout data is empty; the details are provided asynchronously from the store. Defer the trackViewCart
  // until the full data is available, using `hasTracked` to ensure that the tracking event is only created once
  // per page load.
  const [hasTracked, setHasTracked] = useState(false);
  useEffect(() => {
    if (!hasTracked && checkout && checkout.lineItems.length > 0) {
      trackViewCart(window.location.pathname, checkout);
      setHasTracked(true);
    }
  }, [checkout]);

  const lineItems = useLineItems()?.map(
    ({
      item,
      isWyw,
      isExclusive,
      isProSubscription,
      alertLines,
      lineItemMessage,
    }) => {
      const onQuantityChange = (quantity: number) => {
        dispatch(updateLineItem({ lineItemId: item.id, quantity }));
      };
      const onRemove = () => {
        dispatch(removeLineItem({ lineItemId: item.id }));
      };

      return (
        <LineItem
          key={item.id}
          onQuantityChange={onQuantityChange}
          onRemove={onRemove}
          slim={false}
          disabled={isMutating}
          item={item as ShopifyStorefront.CheckoutLineItem}
          isWyw={isWyw}
          isExclusive={isExclusive}
          hideQuantity={isProSubscription}
          alertLines={alertLines}
          {...lineItemMessage}
        />
      );
    },
  );

  return (
    <>
      <div className={styles.fullCart}>
        <Header />
        {lineItems}
      </div>
      <div className={styles.fullCartFooter}>
        <div>
          <FreeRewardToggle />
          <CartSubtotal />
          <Button
            variant='primary'
            label={t('checkout')}
            disabled={checkout?.lineItems.length === 0}
            onClick={() => goToCheckout(checkout, cartItemsBySku)}
          />
        </div>
      </div>
    </>
  );
};

export default FullCart;
