/* eslint-disable @typescript-eslint/no-empty-function */

import React, { useState, useEffect, useRef } from 'react';
import { debounce } from 'lodash';
import styles from './search.module.scss';
import { Icon } from '@whoop/web-components';
import IconButton from '../IconButton';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { ShortResultList } from '../ShortResultList';
import { fuseSearch, FuseSearchResults } from '../../utils/fuseSearch';
import { useAllProducts } from '../../hooks/useAllProducts';
import { PATH_PREFIX } from '../../utils/regions';

const SEARCH_MIN_LENGTH = 3;

const noop = () => {};

const Search = ({ onReset = noop, onResultClick = noop }): JSX.Element => {
  const { t } = useTranslation('search');
  const inputRef = useRef<HTMLInputElement>(null);
  const [searchResults, setSearchResults] = useState<FuseSearchResults[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>('');

  const allProducts = useAllProducts();
  const hasResults = searchResults.length > 0;
  const hasQuery = searchQuery && searchQuery.length > 0;

  const debouncedSearch = debounce((query: string) => {
    if (query.length > SEARCH_MIN_LENGTH) {
      const results = fuseSearch(allProducts!, query);
      setSearchResults(results);
    }
  }, 300);

  const handleChange = (event: React.SyntheticEvent<EventTarget>) => {
    event.preventDefault();
    const query = (event.target as HTMLInputElement).value;
    setSearchQuery(query);
    debouncedSearch(query);
  };

  const totalCount = searchResults.length;
  const infoText = t('numResults', { count: totalCount });
  const urlToSearch = `/${PATH_PREFIX}/search?q=${searchQuery}`;
  const handleReset = (event: React.SyntheticEvent<EventTarget>) => {
    event.preventDefault();
    setSearchResults([]);
    onReset();
  };

  useEffect(() => {
    inputRef.current && inputRef.current.focus();
  }, []);

  return (
    <div className={styles.quickSearch}>
      <form
        onReset={handleReset}
        role='search'
        aria-label={t('aria.quickSearch')}
        method='GET'
        action={`/${PATH_PREFIX}/search`}
      >
        <Icon className={styles.searchIcon} name='search_magnifier' />
        <input
          type='search'
          name='q'
          placeholder={t('searchElipsies')}
          ref={inputRef}
          onChange={handleChange}
          autoComplete='off'
          defaultValue={searchQuery}
        />
        <IconButton type='reset' icon='cross' />
      </form>

      {hasResults && hasQuery && (
        <section className={styles.results}>
          <div className={styles.controls}>
            <span>{infoText}</span>
            <a aria-label={t('aria.viewAllResults')} href={urlToSearch}>
              {t('viewAll')}
            </a>
          </div>
          <ShortResultList
            results={searchResults}
            onClick={onResultClick}
            onReset={onReset}
          />
        </section>
      )}

      {!hasResults && hasQuery && (
        <section className={styles.results}>
          <div className={styles.controls}>
            <span>{t('products')}</span>
          </div>
          <span>{t('noResultsFound')}</span>
        </section>
      )}
    </div>
  );
};

export default Search;
