import React, { useEffect, useState } from 'react';
import { useReduxDispatch } from '../../redux/create-store';
import { Alert, Alerts } from '@whoop/web-components';
import styles from './free-reward-toggle-v2.module.scss';
import { claimReward } from '../../redux/reducers/cartReducer';
import {
  useFreeRewardStatus,
  useHasFreeEligibleItemsInCart,
  useHasProInCart,
  useIsCartLoaded,
  useIsCartMutating,
  useIsEligibleForProCheckout,
  useIsFreeRewardApplied,
  useIsSignedIn,
  useNextRewardDateV2,
  useProMonthlyPrice,
  useProNMonthsPrice,
  useProProratedMonths,
} from '../../redux/hooks';
import {
  useAddProToCart,
  useToggleCart,
  useToggleProModal,
} from '../../redux/action-hooks';
import { useSelector } from 'react-redux';
import { Store } from '../../types';
import { ShopifyStorefront } from '../../types/shopify-storefront';
import {
  stripToken,
  trackToggleFreeItem,
  trackWhoopAnalyticsEvent,
} from '../../utils/analytics';
import { navigate } from 'gatsby';
import { isFreeCheckoutItem } from '../../utils/products';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { BasicComponentProps } from '@whoop/web-components/dist/types';
import { classes } from '../../utils';

export default function FreeRewardToggleV2({
  ...props
}: BasicComponentProps): JSX.Element | null {
  const { t } = useTranslation('cart');
  const freeRewardStatus = useFreeRewardStatus();
  const hasFreeEligibleItems = useHasFreeEligibleItemsInCart();
  const proInCart = useHasProInCart();
  const nextRewardDate = useNextRewardDateV2();
  const isCartLoaded = useIsCartLoaded();
  const isMutating = useIsCartMutating();
  const isChecked = useIsFreeRewardApplied();
  const dispatch = useReduxDispatch();
  const addProToCart = useAddProToCart();
  const isEligibleForProCheckout = useIsEligibleForProCheckout();
  const monthlyPrice = useProMonthlyPrice();
  const proMonthsRequired = useProProratedMonths();
  const creditCost = useProNMonthsPrice(proMonthsRequired || 0);
  const yearlyCost = useProNMonthsPrice(12);
  const isSignedIn = useIsSignedIn();

  const [action, setAction] = useState<undefined | 'remove' | 'apply'>();
  const [actionLabel, setActionLabel] = useState('');
  const [isToggleClicked, setIsToggleClicked] = useState(false);
  const toggleChecked = () => {
    dispatch(claimReward(!isChecked));
    setIsToggleClicked(true);
  };
  const toggleCart = useToggleCart();
  const toggleProModal = useToggleProModal('reward-toggle', true);

  const checkout = useSelector<Store>(
    (state) => state.checkout,
  ) as ShopifyStorefront.Checkout;

  useEffect(() => {
    // Reset cart component state if/when user is changed
    setIsToggleClicked(false);
    setActionLabel('');
    setAction(undefined);
  }, [isSignedIn]);

  useEffect(() => {
    if (!isToggleClicked) {
      setActionLabel(isChecked ? t('remove') : t('v2.apply'));
      setAction(isChecked ? 'remove' : 'apply');
    }
  }, [isChecked]);

  useEffect(() => {
    if (isToggleClicked) {
      if (isChecked) {
        const freeItem = checkout.lineItems.find((item) =>
          isFreeCheckoutItem(item),
        );
        trackToggleFreeItem(true, freeItem);
        setActionLabel(t('remove'));
        setAction('remove');
      } else {
        trackToggleFreeItem(false);
        setActionLabel(t('v2.apply'));
        setAction('apply');
      }
    }
  }, [checkout]);

  if (!isCartLoaded) return null;

  const getLocationPayload = () => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    const location = new URL(stripToken(window.location.href));
    return {
      location: `${location.pathname}${location.search}`,
      pathname: location.pathname,
    };
  };

  const navigateToFreeItems = () => {
    trackWhoopAnalyticsEvent('Choose Now', {
      ...getLocationPayload(),
      source: 'cart',
    });
    toggleCart();
    navigate('/collections/whoop-pro');
  };

  const doAddProToCart = () => {
    trackWhoopAnalyticsEvent('Add Pro to Cart', {
      ...getLocationPayload(),
      source: 'cart',
    });
    addProToCart();
  };

  switch (freeRewardStatus) {
    case 'eligible':
      if (hasFreeEligibleItems) {
        if (proInCart) {
          return (
            <Alert
              iconName='logo_pro'
              type={Alerts.PRO}
              disabled={isMutating}
              {...props}
            >
              <span
                style={{
                  fontWeight: 'normal',
                  textTransform: 'none',
                }}
              >
                {t('v2.wpCTA', {
                  price: creditCost,
                  months: proMonthsRequired,
                  yearlyCost: yearlyCost,
                })}{' '}
                <a
                  className={classes(styles.learnMore, 'no-color')}
                  onClick={toggleProModal}
                >
                  {t('learnMore')}
                </a>
              </span>
            </Alert>
          );
        }
        return (
          <Alert
            actionLabel={actionLabel}
            actionNode={
              action !== 'remove' ? undefined : (
                <button
                  className={styles.removeBtn}
                  onClick={toggleChecked}
                  disabled={isMutating}
                >
                  {t('remove')}
                </button>
              )
            }
            iconName='gift'
            type={Alerts.PRO}
            onAction={toggleChecked}
            disabled={isMutating}
            {...props}
          >
            {action === 'apply' && (
              <>
                <span>{t('v2.claimWPItem')}</span>
                <br />
              </>
            )}
            <span
              style={{
                fontWeight: 'normal',
                textTransform: 'none',
              }}
            >
              {t('v2.highestPricedItemFree')}
            </span>
          </Alert>
        );
      }
      return (
        <Alert
          iconName={'gift'}
          type={Alerts.PRO}
          actionLabel={t('v2.shopFreeItems')}
          actionPosition={'bottom'}
          onAction={navigateToFreeItems}
          {...props}
        >
          <span>{t('v2.freeItemAvailable')}</span>
          <br />
          <span
            style={{
              fontWeight: 'normal',
              textTransform: 'none',
            }}
          >
            {t('v2.chooseNow')}
          </span>
        </Alert>
      );
    case 'will-be-eligible':
      return (
        <Alert type={Alerts.PRO} {...props}>
          <span>
            {nextRewardDate
              ? t('v2.claimNextFreeItemOn', { date: nextRewardDate })
              : t('v2.claimNextFreeItemAfterActivation')}
          </span>
        </Alert>
      );
    case 'pending':
      return (
        <Alert type={Alerts.PRO} {...props}>
          <span>{t('cannotClaimUntilPaid')}</span>
        </Alert>
      );
    default:
      if (hasFreeEligibleItems && isEligibleForProCheckout) {
        return (
          <Alert
            actionIcon='add'
            onAction={doAddProToCart}
            type={Alerts.PRO}
            {...props}
          >
            <span>{t('v2.freeItemThisOrder')}</span>
            <br />
            <span
              style={{
                fontWeight: 'normal',
                textTransform: 'none',
              }}
            >
              {t('addWPPerMonth', { price: monthlyPrice })}{' '}
              <a
                className={classes(styles.learnMore, 'no-color')}
                onClick={toggleProModal}
              >
                {t('learnMore')}
              </a>
            </span>
          </Alert>
        );
      }
      return null;
  }
}
