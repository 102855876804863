import React, { FormEvent, useEffect, useState } from 'react';
import styles from './sign-in-form.module.scss';
import { Button, Input } from '@whoop/web-components';
import { openJoin, safeOpen } from '../../utils';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { APP_URL } from '../../utils/regions';
import { useReduxDispatch } from '../../redux/create-store';
import { loginUser } from '../../redux/reducers/userReducer';
import { useIsUserLoaded, useLoginError } from '../../redux/hooks';
import { loadCart } from '../../redux/reducers/cartReducer';

export interface SignInFormProps {
  redirect?: string; // redirect url to continue to after sign in (optional)
}

export default function SignInForm({ redirect }: SignInFormProps): JSX.Element {
  const { t } = useTranslation('login');
  const dispatch = useReduxDispatch();
  const isIndeterminate = !useIsUserLoaded();
  const [email, _setEmail] = useState<string>();
  const [emailError, setEmailError] = useState<string>();
  const loginError = useLoginError();
  useEffect(() => {
    if (loginError) {
      setEmailError(t(loginError));
    }
  }, [loginError]);

  const setEmail = (text: string) => {
    setEmailError(undefined);
    _setEmail(text);
  };
  const validateEmail = () => {
    if (!email || email.length === 0) {
      setEmailError(t('emailEmpty'));
      return false;
    }
    if (!/^.*@.*$/.test(email)) {
      setEmailError(t('emailInvalid'));
      return false;
    }
    setEmailError(undefined);
    return true;
  };

  const [password, _setPassword] = useState<string>();
  const [passwordError, setPasswordError] = useState<string>();
  const setPassword = (text: string) => {
    setPasswordError(undefined);
    _setPassword(text);
  };
  const validatePassword = () => {
    if (!password || password.length === 0) {
      setPasswordError(t('passwordEmpty'));
      return false;
    }
    setPasswordError(undefined);
    return true;
  };

  const handleSignInClick = async (event: FormEvent) => {
    event.preventDefault();

    const validEmail = validateEmail();
    const validPassword = validatePassword();
    if (email && password && validEmail && validPassword) {
      await dispatch(
        loginUser({
          username: email,
          password,
        }),
      );
      await dispatch(loadCart());
      if (redirect) {
        safeOpen(redirect, '_self');
      }
    }
  };

  return (
    <form
      className={styles.signInForm}
      method='post'
      action='#'
      onSubmit={handleSignInClick}
    >
      <Input
        label={t('email')}
        value={email}
        onChange={setEmail}
        onBlur={validateEmail}
        error={emailError}
        subText={emailError}
        style={{ marginBottom: 'var(--spacing-sm)' }}
        disabled={isIndeterminate}
      />
      <Input
        label={t('password')}
        type='password'
        value={password}
        onChange={setPassword}
        onBlur={validatePassword}
        error={passwordError}
        subText={passwordError}
        style={{ marginBottom: 'var(--spacing-sm)' }}
        disabled={isIndeterminate}
      />

      <Button
        label={t('signIn')}
        aria-label={t('aria.signIn')}
        variant='primary'
        type='submit'
        disabled={isIndeterminate}
      />

      <a
        href={`${APP_URL}/login/forgot`}
        target='_blank'
        rel='noreferrer noopener'
      >
        {t('forgotPassword')}
      </a>

      <Button
        label={t('joinWhoop')}
        variant='secondary'
        onClick={openJoin}
        disabled={isIndeterminate}
      />
    </form>
  );
}
