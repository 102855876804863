/**
 * This file is copied from web-checkout and is only intended to live in storefront temporarily
 * since it will be transitioned to web-analytics soon
 */

import { getUrl } from './queryParamUtils';
import { ampSetUserProperty } from './analytics';

/**
 * List of all current experiments. When creating a new experiment, first add it to this object
 *
 * Example experiment:
 * 'experiment_joinflow_free_shipping': {
 *    active: true,
 *    allTraffic: true,
 *    featureFlag: 'free-shipping-test',
 *    variants: [
 *      { id: 'control', weight: 0.5 },
 *      { id: 'free_shipping', weight 0.5 }
 *    ]
 * }
 *
 * Experiment elements:
 * - id: The naming convention for experiment ids is typically 'experiment_joinflow_[name]' to make setting up analytics dashboards easier.
 * - variants: The list of possible variants in the experiment. Each one has a weight, and all weights should add up to 1. An A/B test would
 *              consist of two variants each with 0.5 weights. Your first variant should be the control if there is one.
 * - active: true/false - Is the experiment live? Not necessary if experiment is controlled by a feature flag
 * - featureFlag: The name of the feature flag that the experiment is behind. When trying to start the experiment, the feature flag state will be fetched first.
 *                 It's recommended to put all experiments behind feature flags so they can easily be enabled/disabled without a code change.
 * - allTraffic: true/false - Should the experiment be run for all traffic starting from the landing page?
 *                Some examples of when this would be false:
 *                  - The experiment should only run on upgraders
 *                  - The experiment only affects the accessories page, so it should only run on traffic that gets to the accessories step
 */
const experiments = {
  experiment_summer_sale_banner_color: {
    active: false,
    allTraffic: true,
    variants: [
      { id: 'control', weight: 0.5 },
      { id: 'teal', weight: 0.5 },
    ],
  },
  experiment_summer_sale_banner_target: {
    active: false,
    allTraffic: true,
    variants: [
      { id: 'control', weight: 0.5 },
      { id: 'new_tab', weight: 0.5 },
    ],
  },
  experiment_live_chat: {
    allTraffic: false,
    active: true,
    featureFlag: 'storefront-live-chat',
    variants: [
      { id: 'control', weight: 0.5 },
      { id: 'live_chat', weight: 0.5 },
    ],
  },
  experiment_cart_redesign: {
    allTraffic: false,
    active: true,
    featureFlag: 'storefront-cart-redesign',
    variants: [
      { id: 'control', weight: 0.0 },
      { id: 'with_upsell', weight: 0.0 },
      { id: 'no_upsell', weight: 1.0 },
    ],
  },
  experiment_sale_and_reward_banners: {
    allTraffic: false,
    active: true,
    featureFlag: 'storefront-sale-and-reward-banner',
    variants: [
      { id: 'one_banner', weight: 0.1 },
      { id: 'both_banners', weight: 1.0 },
    ],
  },
  experiment_pdp_redesign: {
    allTraffic: true,
    active: true,
    featureFlag: 'storefront-pdp-redesign',
    variants: [
      { id: 'with_sticky_pdp', weight: 0.0 },
      { id: 'no_sticky_pdp', weight: 1.0 },
    ],
  },
  experiment_product_card_redesign: {
    allTraffic: true,
    active: true,
    featureFlag: 'storefront-product-card-redesign',
    variants: [
      { id: 'with_large_product_card', weight: 0.0 },
      { id: 'no_large_product_card', weight: 1.0 },
    ],
  },
  experiment_navigation: {
    allTraffic: true,
    active: true,
    featureFlag: 'storefront-navigation-test',
    variants: [
      { id: 'control', weight: 0.5 },
      { id: 'variant', weight: 0.5 },
    ],
  },
  experiment_collection_redesign: {
    allTraffic: true,
    active: true,
    featureFlag: 'storefront-collection-redesign',
    variants: [
      { id: 'control', weight: 0.34 },
      { id: 'scroll', weight: 0.33 },
      { id: 'new', weight: 0.33 },
    ],
  },
};

/**
 * Enable one variant at random based on the experiment's variants and their weights
 * Source: https://blobfolio.com/2019/randomizing-weighted-choices-in-javascript/
 * @param {Array} variants - Each variant is an object with an id and a weight
 * @returns String - the id of the enabled variant
 */
const randomlySelectVariant = (variants) => {
  let total = variants.reduce((acc, it) => (acc += it.weight), 0);
  const threshold = Math.random() * total;

  total = 0;
  for (let i = 0; i < variants.length - 1; ++i) {
    total += variants[i].weight;
    if (total >= threshold) {
      return variants[i].id;
    }
  }

  return variants[variants.length - 1].id;
};

const experimentIsActive = (experiment) => {
  return experiment?.active; // TODO || isFeatureFlagOn(experiment?.featureFlag);
};

const setExperiment = (id, variant) => {
  if (!experiments[id]) {
    console.warn(new Error(`${id} is not a valid experiment`));
    return;
  }
  const experiment = experiments[id];
  if (!experimentIsActive(experiment)) {
    console.warn(new Error(`${id} is not an active experiment`));
    return;
  }
  if (!experiment.variants.find(({ id }) => variant === id)) {
    console.error(
      new Error(`${variant} is not valid variant for experiment ${id}`),
    );
    return;
  }
  window.localStorage.setItem(id, variant);
  experiment.variant = variant;
  ampSetUserProperty(id, variant);
};

/**
 * Start an experiment. This is only necessary to use for experiments that are not on for all traffic and should be called
 * when it is determined that the current user is eligible for the experiment.
 * Experiments that are on for all traffic will be started automatically when the landing page is loading.
 * If the provided experiment id belongs to an inactive experiment, instead of being started, its enabled variant will be cleared
 * @param {String} id - id of the experiment to be started
 * @returns String - the id of the enabled variant for the experiment
 */
export const startExperiment = (id) => {
  const experiment = experiments[id];
  if (experiment.variant) {
    return experiment.variant; // experiment has already started
  }
  const isDatadog = navigator.userAgent.includes('DatadogSynthetics');
  if (isDatadog || !experimentIsActive(experiment)) {
    clearExperiment(id);
    return;
  }
  const savedVariant = window.localStorage.getItem(id);
  const variant = savedVariant || randomlySelectVariant(experiment.variants);
  setExperiment(id, variant);
  return variant;
};

/**
 * Sets experiments from URL query parameters if there are any.
 * Example: `join.whoop.com?experiment_wyw_card=large_card
 */
const setExperimentsFromUrl = () => {
  // Set experiment based on query params
  //  but this is mostly for us to control the experiment via query params
  const params = getUrl().query;
  Object.entries(params).forEach(([key, value]) => {
    if (key?.startsWith('experiment_')) {
      setExperiment(key, value);
    }
  });
  // special rule to disable all experiments
  if (params.all_experiments_off) {
    Object.values(experiments).forEach((experiment) => {
      experiment.active = false;
      experiment.featureFlag = null;
    });
  }
};

/**
 * Start all experiments that are set to run for all traffic.
 * Any inactive experiments will have their enabled variants cleared.
 * Also serves as an init function
 */
export const startAllTrafficExperiments = () => {
  clearAllInactiveExperiments();
  setExperimentsFromUrl();
  for (const id in experiments) {
    if (experiments[id].allTraffic) {
      startExperiment(id);
    }
  }
};

/**
 * Reset an experiment so that no variant is selected for the current user
 * @param {String} id - id of the experiment to be cleared
 */
const clearExperiment = (id) => {
  const experiment = experiments[id];
  if (experiment) {
    delete experiment.variant;
  }
  if (window.localStorage.getItem(id)) {
    window.localStorage.removeItem(id);
  }
};

/**
 * Removes any `experiment_*` from local storage that are not currently active
 */
const clearAllInactiveExperiments = () => {
  for (const key in localStorage) {
    if (key?.startsWith(`experiment_`)) {
      const experiment = experiments[key];
      if (!experimentIsActive(experiment)) {
        clearExperiment(key);
      } else {
        const savedVariant = window.localStorage.getItem(key);
        if (
          !experiment?.variants?.find(({ id }) => savedVariant === id)?.weight
        ) {
          // if weight is 0 select a random one
          clearExperiment(key);
        }
      }
    }
  }
};

/**
 * Get the id of the variant that is enabled for the given experiment
 * @param {String} experimentId
 * @returns String - the id of the enabled variant
 */
export const getVariant = (experimentId) => {
  return experiments?.[experimentId]?.variant;
};

/**
 * Determine whether or not the given variant is enabled for the given experiment.
 * With our example 'experiment_joinflow_free_shipping' experiment, we would show free shipping only when
 * isVariant('experiment_joinflow_free_shipping', 'free_shipping') is true
 * @param {*} experimentId
 * @param {*} variantId
 * @returns true if the given variant is currently enabled, else false
 */
export const isVariant = (experimentId, variantId) => {
  const actualVariant = getVariant(experimentId);
  return (
    (variantId === 'control' && !actualVariant) || actualVariant === variantId
  );
};

/**
 * Determine if the experiment is control.
 * @param experimentId
 * @returns {*}
 */
export const isControl = (experimentId) => isVariant(experimentId, 'control');

/**
 * Get a list of all experiment variants the current user is in. Helpful for debugging errors and matching orders to experiments.
 * @returns String - all ids of the experiments running with the enabled variant for each
 */
export const getActiveExperimentsKey = () => {
  const experimentList = [];
  for (const experimentId in experiments) {
    const variant = experiments[experimentId].variant;
    if (typeof variant === 'undefined') continue;
    experimentList.push(`${experimentId}.${variant}`);
  }
  return experimentList.join('!');
};
