import {
  BASE_WHOOP_URL,
  getJoinUrl as _getJoinUrl,
} from '@whoop/web-components/dist/utils';

export const JOIN_DOMAIN = ((shopRegion) => {
  if (shopRegion === 'DEV') {
    return 'join.qa.whoop.com';
  }
  return 'join.whoop.com';
})(process.env.ENV);

export const APP_DOMAIN = ((shopRegion) => {
  if (shopRegion === 'DEV') {
    return 'app.qa.whoop.com';
  }
  return 'app.whoop.com';
})(process.env.ENV);

export const API_PROTOCOL = 'https';

export const API_DOMAIN = ((shopRegion) => {
  if (shopRegion === 'DEV') {
    return 'api.dev.whoop.com';
  }
  return 'api.prod.whoop.com';
})(process.env.ENV);

export const IS_PROD = ((shopEnv) => {
  return shopEnv !== 'DEV';
})(process.env.ENV);

// IMPORTANT: to prevent type errors this should always match what's in globals.d.ts
export type ShopRegionCode = 'AE' | 'AU' | 'CA' | 'DEV' | 'EU' | 'UK' | 'US';
export type ShopLanguageCode = 'en' | 'de' | 'fr' | 'es' | 'it' | 'pt';
export type CurrencyCode = 'USD' | 'AUD' | 'EUR' | 'GBP' | 'AED' | 'CAD';
export type BillingRegionCode =
  | 'domestic'
  | 'australia'
  | 'international'
  | 'uk'
  | 'uae'
  | 'ca'
  | 'nz'
  | 'sar';

// This is only a tiny sample of the possible paths. If you need to add a new one, go to https://url.prod.whoop.com/
export type SupportPath =
  | 'SUPPORT'
  | 'RETURN_POLICY'
  | 'WHOOP_WARRANTY_POLICY'
  | 'WHOOP_PRO_FAQ';

function getCurrencyCode(shopRegion: ShopRegionCode): CurrencyCode {
  switch (shopRegion) {
    case 'EU': // Europe
      return 'EUR';
    case 'UK': // United Kingdom
      return 'GBP';
    case 'AE': // United Arab Emirates
      return 'AED';
    case 'AU': // Australia
      return 'AUD';
    case 'CA': // Canada
      return 'CAD';
    default:
      // United States (includes DEV site)
      return 'USD';
  }
}

function getShopifyCdnUrl(
  shopRegion: ShopRegionCode,
  env: string | undefined,
): string {
  if (env === 'DEV' || undefined) {
    return 'cdn.shopify.com/s/files/1/0378/2560/9865';
  }
  switch (shopRegion) {
    case 'EU': // Europe
      return 'cdn.shopify.com/s/files/1/0059/2150/5378';
    case 'UK': // United Kingdom
      return 'cdn.shopify.com/s/files/1/0521/9801/5159';
    case 'AE': // United Arab Emirates
      return 'cdn.shopify.com/s/files/1/0476/3241/6929';
    case 'AU': // Australia
      return 'cdn.shopify.com/s/files/1/0263/9013/4883';
    case 'CA': // Canada
      return 'cdn.shopify.com/s/files/1/0520/9420/6127';
    case 'US':
    default:
      // United States
      return 'cdn.shopify.com/s/files/1/1040/0152';
  }
}

function getWhoopYourWayCustomizationFee(shopRegion: ShopRegionCode): number {
  switch (shopRegion) {
    case 'EU': // Europe
      return 10;
    case 'UK': // United Kingdom
      return 10;
    case 'AE': // United Arab Emirates
      return 35;
    case 'AU': // Australia
      return 10;
    case 'CA': // Canada
      return 10;
    case 'DEV':
    case 'US':
    default:
      return 10;
  }
}

function getBillingRegion(shopRegion: ShopRegionCode): BillingRegionCode {
  switch (shopRegion) {
    case 'EU':
      return 'international';
    case 'UK':
      return 'uk';
    case 'AE':
      return 'uae';
    case 'AU':
      return 'australia';
    case 'CA':
      return 'ca';
    default:
      return 'domestic';
  }
}

export function getShopRegionCode(
  billingRegion: BillingRegionCode,
): ShopRegionCode {
  switch (billingRegion) {
    case 'international':
      return 'EU';
    case 'uk':
      return 'UK';
    case 'uae':
      return 'AE';
    case 'australia':
      return 'AU';
    case 'ca':
      return 'CA';
    default:
      return process.env.SHOP_REGION; // US or DEV
  }
}

function getShopUrl(shopRegion: ShopRegionCode): string {
  switch (shopRegion) {
    case 'EU':
      return 'https://shop.eu.whoop.com';
    case 'UK':
      return 'https://shop.uk.whoop.com';
    case 'AE':
      return 'https://shop.uae.whoop.com';
    case 'AU':
      return 'https://shop.au.whoop.com';
    case 'CA':
      return 'https://shop.ca.whoop.com';
    default:
      return 'https://shop.us.whoop.com';
  }
}

function getJoinUrl(): string {
  return _getJoinUrl(IS_PROD);
}

function getAppUrl(): string {
  return `https://${APP_DOMAIN}`;
}

export function getSupportUrl(supportPath: SupportPath = 'SUPPORT'): string {
  return `https://${API_DOMAIN}/url-mapping-service/v1/${supportPath}`;
}

function getOrderStatusUrl(): string {
  return `https://orderstatus.whoop.com`;
}

function getWhoopUrl(): string {
  return BASE_WHOOP_URL;
}

function getSkuSuffix(shopRegion: ShopRegionCode): string {
  switch (shopRegion) {
    case 'EU':
      return '-E';
    case 'UK':
      return '-G';
    case 'AE':
      return '-U';
    case 'AU':
      return '-A';
    case 'CA':
      return '-C';
    default:
      return '';
  }
}

// NOTE: Shipping threshold prices MUST be kept in sync between this function
// and the thresholds in shopify-scripts/shipping.rb
export function getFreeShippingPrice(
  shopRegion: ShopRegionCode,
): number | undefined {
  switch (shopRegion) {
    case 'EU':
      return 75; // 75 euros
    case 'UK':
      return 60; // 60 gpb
    case 'AE':
      return undefined;
    case 'AU':
      return 100; // 100 AUD
    case 'CA':
      return 100; // 100 CAD
    case 'US':
      return 75; // $75 USD
    default:
      return undefined;
  }
}

export const SHOP_CURRENCY_CODE = getCurrencyCode(process.env.SHOP_REGION);
export const SHOPIFY_CDN_URL = getShopifyCdnUrl(
  process.env.SHOP_REGION,
  process.env.ENV,
);
export const SKU_SUFFIX = getSkuSuffix(process.env.SHOP_REGION);
export const BILLING_REGION = getBillingRegion(process.env.SHOP_REGION);
export const JOIN_URL = getJoinUrl();
export const SUPPORT_URL = getSupportUrl('SUPPORT');
export const APP_URL = getAppUrl();
export const ORDER_STATUS_URL = getOrderStatusUrl();
export const WHOOP_URL = getWhoopUrl();
export const WHOOP_YOUR_WAY_CUSTOMIZATION_FEE = getWhoopYourWayCustomizationFee(
  process.env.SHOP_REGION,
);
export const SHOP_URL = getShopUrl(process.env.SHOP_REGION);

export const PREFER_METRIC =
  process.env.SHOP_LANGUAGE !== 'en' ||
  (process.env.SHOP_REGION !== 'US' && process.env.ENV !== 'DEV');

export const PATH_PREFIX = `${process.env.SHOP_LANGUAGE.toLowerCase()}-${process.env.SHOP_REGION.toLowerCase()}`;

export const FREE_SHIPPING_THRESHOLD = getFreeShippingPrice(
  process.env.SHOP_REGION,
);

export const FREE_SHIPPING_FEATURE_FLAG = `storefront-free-shipping-${process.env.SHOP_REGION.toLowerCase()}`;
