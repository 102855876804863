import {
  identifyUser,
  trackAddToCart,
  trackChangeQuantity,
  trackLogout,
  trackRemoveFromCart,
  trackWhoopProModalOpened,
} from '../../utils/analytics';
import { itemToLineItemProps } from '../../utils/products';
import { isMemberExtension } from '../../utils/productUtils';

const getLineItemFromCheckout = function (checkout, lineItemId) {
  return checkout.lineItems
    ? checkout.lineItems.find((item) => item.id === lineItemId)
    : undefined;
};

const analyticsMiddleware = (store) => (next) => (action) => {
  const { type, payload } = action;

  // Actions to be processed before the Store state is updated
  try {
    switch (type) {
      case 'cart/updateLineItem/fulfilled': {
        const { checkout } = store.getState();
        const { lineItemId, quantity } = action.meta.arg;
        const lineItem = getLineItemFromCheckout(checkout, lineItemId);

        if (lineItem) {
          if (lineItem.variant) {
            trackChangeQuantity(lineItem.variant, lineItem.quantity, quantity);
          } else {
            // There should always be a valid variant in the store's checkout for
            // the line item.
            console.error(
              `Error tracking cart update: variant not found for line item ${lineItemId}`,
            );
          }
        } else {
          // There should always be a valid  item in the store's checkout for
          // the line item id.
          console.error(
            `Error tracking cart update: item not found for line item ${lineItemId}`,
          );
        }
        break;
      }
      case 'cart/removeLineItem/fulfilled': {
        const { checkout } = store.getState();
        const { lineItemId } = action.meta.arg;
        const lineItem = getLineItemFromCheckout(checkout, lineItemId);

        if (lineItem) {
          if (lineItem.variant) {
            trackRemoveFromCart(lineItem.variant);
          } else {
            // There should always be a valid variant in the store's checkout for
            // the line item.
            console.error(
              `Error tracking removing from cart: variant not found for line item ${lineItemId}`,
            );
          }
        } else {
          // There should always be a valid item in the store's checkout for
          // the line item id.
          console.error(
            `Error tracking removing from cart: item not found for line item ${lineItemId}`,
          );
        }
        break;
      }
    }
  } catch (e) {
    console.error(e);
  }

  const nextState = next(action);

  // Actions to be processed after the Store state was updated
  try {
    if (type?.startsWith('cart/') && type.includes('/fulfilled')) {
      const state = store.getState();
      const user = state?.user;
      const status = state?.whoopProStatus?.status;
      const isWhoopPro =
        status === 'active' || status === 'expiring' || status === 'pending';
      const checkout = state?.checkout;
      if (isWhoopPro && !user?.isEmployee && checkout?.lineItems?.length > 0) {
        const proDiscountLineItems = checkout?.lineItems
          ?.map(itemToLineItemProps)
          ?.filter(
            (item) =>
              !item?.sku?.startsWith('GFT') && !isMemberExtension(item?.sku),
          )
          ?.filter((item) => !item?.isProDiscount);
        if (proDiscountLineItems?.length > 0) {
          console.error(
            `Shopify Cart Missing Pro Discounts (Cart Id ${checkout?.id})`,
          );
        }
      }
    }

    switch (type) {
      case 'user/loadUser/fulfilled': {
        identifyUser(payload?.user);
        break;
      }
      case 'user/removeUser': {
        trackLogout();
        break;
      }
      case 'cart/addVariantToCart/fulfilled': {
        const { checkout } = payload;
        const { variantId, quantity, is3d, customAttributes, item } =
          action.meta.arg;

        if (checkout.lineItems) {
          const lineItem = checkout.lineItems.find(
            (item) => item.variant.id === variantId,
          );
          if (lineItem && lineItem.variant) {
            trackAddToCart(
              lineItem.variant,
              quantity,
              is3d,
              false,
              customAttributes,
              item,
            );
          } else {
            // We should always be able to find the item added to the checkout data
            console.error(
              `Error tracking add to cart: item not found for variant ${variantId}`,
            );
          }
        }
        break;
      }
      case 'view/toggleProModal': {
        const { showProModal } = store.getState();
        if (showProModal) {
          const { location, source, fromCart } = payload;
          trackWhoopProModalOpened(location, source, fromCart);
        }
        break;
      }
    }
  } catch (e) {
    console.error(e);
  }

  return nextState;
};

export default analyticsMiddleware;
