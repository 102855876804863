import React from 'react';
import styles from './sock.module.scss';
import { Button, ProLogo } from '@whoop/web-components';
import { Link } from 'gatsby';
import { classes, loginLink } from '../../utils';
import {
  useIsCartMutating,
  useIsEligibleForProCheckout,
  useIsSignedIn,
  useProCtaButtonContent,
  useProLandingContent,
} from '../../redux/hooks';
import { useJoinWhoopPro, useToggleProModal } from '../../redux/action-hooks';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { formatPrice } from '../../utils/priceUtils';

export default function Sock(): JSX.Element {
  const { t } = useTranslation('sock');
  const landingContent = useProLandingContent();
  const toggleProModal = useToggleProModal('sock');
  const joinWhoopPro = useJoinWhoopPro();
  const buttonContent = useProCtaButtonContent();
  const showSignIn = !useIsSignedIn();
  const isCartMutating = useIsCartMutating();
  const isEligibleForProCheckout = useIsEligibleForProCheckout();

  return (
    <section className={styles.container} data-impress='Viewed WP Sock Banner'>
      <ProLogo height={80} width={80} className={styles.proLogoDesktop} />
      <ProLogo height={60} width={60} className={styles.proLogoMobile} />

      {landingContent && (
        <>
          <div className={styles.titleContainer}>
            <h2 className={styles.subtitle}>{t('freeItemCadence')}</h2>
          </div>
          <p className={styles.footer}>
            {t('wpFreeItemAndPercentOff', {
              percent: landingContent?.store_discount,
              price: formatPrice(landingContent?.unformatted_monthly_price),
            })}
            &nbsp;
            <a className='pro-anchor no-color' onClick={toggleProModal}>
              {t('learnMore')}
            </a>
          </p>

          <Button
            label={buttonContent}
            variant='primary'
            size='large'
            style={{
              '--color-primary': 'var(--whoop-color-pro-gold)',
              '--color-primary-contrast': 'var(--whoop-color-black)',
              fontWeight: 'bold',
            }}
            data-event={
              isEligibleForProCheckout ? 'Add Pro to Cart' : undefined
            }
            data-event-source='sock'
            onClick={() => joinWhoopPro('sock')}
            disabled={isCartMutating}
          />
          {showSignIn && (
            <p className={styles.footer}>
              <Link
                to={loginLink()}
                className={classes(styles.signInAnchor, 'no-color')}
              >
                {t('signIn')}
              </Link>
            </p>
          )}
        </>
      )}
    </section>
  );
}
